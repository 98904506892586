/**
 * We only want to import the file if the condition allows to eliminate auto-run side effects
 * setTimeout(0) gives the SG1 credit card web component a chance to initialize
 * Some browsers will fail to render the form correctly without this
 *
 * CollectPaymentForm imports components/JobberOnline/jobberOnline, which asks for
 * window.JobberOnline too early. Even though the module isn't triggered, but the side effect
 * still happened and causes an error because we `export const jobberOnline = JobberOnline`
 */

declare const Rollbar: {
  error(message: string): void;
};

export const setupCollectPaymentForm = (sectionName: string) => {
  let eventName = "";
  let formElementId = "";

  if (sectionName === "bunker") {
    // Visible when SC is making a payment to accounts with e_payment settings enabled
    eventName =
      "bunker:direct_chargers:pay_now_dialog bunker:direct_chargers:pay_deposit_dialog";
    formElementId = "new_e_payment_direct_charger";
  } else if (sectionName === "jobber") {
    // Visible triggering "Save and Collect Payment" from Jobber Online
    eventName = "payments:payments:new payments:payments:record_payment";
    formElementId = "new_balance_adjustment";
  }

  $(document).on(eventName, () => {
    const formElement = document.getElementById(
      formElementId,
    ) as HTMLFormElement;

    if (formElement !== null) {
      import("jobber/payments/CollectPaymentForm")
        .then(({ CollectPaymentForm }) => {
          setTimeout(() => new CollectPaymentForm(formElement), 0);
        })
        .catch((error: string) => {
          Rollbar.error(
            `Problem initializing SG1 CollectPaymentForm, ${error}`,
          );
        });
    }
  });
};
