/**
 * Primary pack entry point.
 */
import "@babel/polyfill";
import "formdata-polyfill";
import cssVars from "css-vars-ponyfill";
import { setupCollectPaymentForm } from "jobber/payments/SetupCollectPaymentForm";
import "whatwg-fetch";
import { initSharedResources } from "./sharedResources";

cssVars({
  watch: true,
});

const sectionContext = require.context("bunker", true);
initSharedResources(sectionContext);

setupCollectPaymentForm("bunker");
